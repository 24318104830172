
.swiper-home .swiper-pagination-bullet-active {
	opacity: 1;
	background: #5E646E;
}

.swiper-home .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 10px
}

.swiper-home .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    bottom: 0;
}

.swiper-home .swiper-button-next {
    right: 0;

    background-image: url(/swiper/arrow-left.svg);
    background-repeat: no-repeat;
    background-size: 24.72px auto;
    background-position: center;

    transform: rotate(180deg);

    background-color: #fff;

    width: 72px;
    height: 72px;

    border-radius: 100px;
}
  
.swiper-home .swiper-button-next::after {
    display: none;
}

.swiper-home .swiper-button-prev {
    left: 0;

    background-image: url(/swiper/arrow-left.svg);
    background-repeat: no-repeat;
    background-size: 24.72px auto;
    background-position: center;

    background-color: #fff;

    width: 72px;
    height: 72px;

    border-radius: 100px;
}
  
.swiper-home .swiper-button-prev::after {
    display: none;
}

@media screen and (max-width: 639px) {
    .swiper-home .swiper-button-next {
        display: none;
    }
    .swiper-home .swiper-button-prev {
        display: none;
    }
}